@import "variables";
@import "bootstrap/scss/bootstrap";

html {
  font-size: 14px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

// Override Firebase Google Authentication logins
#firebaseui_container {
  .firebaseui-container {
    margin: 0;
  }
  .firebaseui-card-content {
    padding: 0;
  }
  .firebaseui-idp-button {
    max-width: none;
  }
  .mdl-button {
    border-radius: $border-radius-sm;
  }
}

// Custom Navbar
.my-navbar {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);

  position: sticky;
  top: 0;
  height: 97px;
  display: grid;
  align-items: center;

  .col {
    display: grid;
    align-items: center;

    .nav-link {
      text-align: right;
      img.profile-picture {
        max-height: 40px;
        border-radius: 40px;
        background-color: $gray-500;
        margin-left: 1rem;
      }
    }
  }
}
