@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800&display=swap');

// Override defaults
$body-bg: #F4F5F7;
$body-color: #212121;
$font-family-sans-serif: 'Manrope', sans-serif;

$primary: #1E3BB3;
$link-color: #4863D2;
$link-decoration: none;


$border-radius:         15px;                              //        .375rem !default;
$border-radius-sm:      calc($border-radius / .375 * .25); //        .25rem !default;
$border-radius-lg:      calc($border-radius / .375 * .5);  //        .5rem !default;
$border-radius-xl:      calc($border-radius / .375 * 1);   //        1rem !default;
$border-radius-2xl:     calc($border-radius / .375 * 2);   //        2rem !default;
$border-radius-pill:    calc($border-radius / .375 * 50);  //        50rem !default;

$card-border-color:     transparent;
$card-cap-bg:           transparent;
$card-spacer-y:         1rem;
$card-spacer-x:         1.5rem;
$card-cap-padding-y:    $card-spacer-y * 1.5;

$h1-font-size:          2rem;
$h2-font-size:          1.15rem;
$h3-font-size:          1rem;
$h4-font-size:          0.786rem;
$headings-font-weight:  800;
h4 { text-transform: uppercase; }
